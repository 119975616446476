import React, { useState, useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { observer, inject } from "mobx-react";
import PaymentSuccessModal from "./PaymentSuccessModal";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const CheckoutForm = inject("store")(
  observer(({ store, product, onSuccess, onError }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [cardholderName, setCardholderName] = useState(
      `${store.profile.fname} ${store.profile.lname}`.trim()
    );
    const [email] = useState(store.profile.email);
    const [country, setCountry] = useState("US");

    useEffect(() => {
      return () => {
        if (elements) {
          const card = elements.getElement(CardElement);
          if (card) {
            card.destroy();
          }
        }
      };
    }, [elements]);

    const [isSubmitting, setIsSubmitting] = useState(false);

    const handlePaymentSuccess = async () => {
      try {
        setShowSuccessModal(false);

        window.location.href = "/dashboard";
      } catch (err) {
        console.error("Error during redirect:", err);
      }
    };

    const handleSubmit = async (event) => {
      event.preventDefault();

      if (!stripe || !elements || isSubmitting) {
        return;
      }

      setIsSubmitting(true);
      setProcessing(true);
      setError(null);

      try {
        const { error: cardError, paymentMethod } =
          await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement),
            billing_details: {
              name: cardholderName,
              email: email,
            },
          });

        if (cardError) {
          throw new Error(cardError.message);
        }

        const response = await store.api.post("/stripe/create-subscription", {
          paymentMethodId: paymentMethod.id,
          priceId: product.priceId,
          email: email,
          name: cardholderName,
        });

        if (response.data.status === "requires_action") {
          const { error: confirmationError } = await stripe.confirmCardPayment(
            response.data.clientSecret,
            {
              payment_method: paymentMethod.id,
            }
          );

          if (confirmationError) {
            throw new Error(confirmationError.message);
          }
        }

        const verificationResponse = await store.api.post(
          "/stripe/verify-subscription",
          {
            subscriptionId: response.data.subscriptionId,
            customerId: response.data.customerId,
          }
        );

        if (!verificationResponse.data.success) {
          throw new Error("Subscription verification failed");
        }

        await store.refreshTokenAndProfile();

        if (window.refreshInterval) {
          clearInterval(window.refreshInterval);
          window.refreshInterval = null;
        }

        setShowSuccessModal(true);

        setTimeout(() => {
          store.refreshTokenAndProfile();
        }, 2000);
      } catch (err) {
        console.error("Payment error:", err);
        setError(err.message || "An unexpected error occurred.");
        if (onError) onError(err);
      } finally {
        setProcessing(false);
        setIsSubmitting(false);
      }
    };

    return (
      <>
        <form onSubmit={handleSubmit} className="space-y-4">
          <h3 className="text-xl font-semibold mb-4">Pay with card</h3>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Email
            </label>
            <input
              type="email"
              value={email}
              readOnly
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 bg-gray-50"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Card information
            </label>
            <div className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3">
              <CardElement options={CARD_ELEMENT_OPTIONS} />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Cardholder name
            </label>
            <input
              type="text"
              value={cardholderName}
              onChange={(e) => setCardholderName(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Country or region
            </label>
            <select
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3"
            >
              <option value="US">United States</option>
              <option value="PH">Philippines</option>
            </select>
          </div>

          {error && (
            <div
              className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded relative"
              role="alert"
            >
              <span className="block sm:inline">{error}</span>
            </div>
          )}

          <button
            type="submit"
            disabled={!stripe || processing}
            className={`w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 disabled:opacity-50 ${
              processing ? "cursor-not-allowed" : "cursor-pointer"
            }`}
          >
            {processing ? (
              <span className="flex items-center justify-center">
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                Processing...
              </span>
            ) : (
              "Subscribe"
            )}
          </button>

          <p className="text-sm text-gray-500 mt-4">
            By confirming your subscription, you allow Navix Health Inc to
            charge you for future payments in accordance with their terms. You
            can always cancel your subscription.
          </p>
        </form>

        <PaymentSuccessModal
          isOpen={showSuccessModal}
          onClose={handlePaymentSuccess}
        />
      </>
    );
  })
);

export default CheckoutForm;
