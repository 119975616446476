import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { XIcon, TrashIcon, PencilIcon } from "@heroicons/react/solid";
import { inject, observer } from "mobx-react";
import { formatDistance } from "date-fns";
import {
  getRecordings,
  updateRecordingTitle,
  deleteRecording,
} from "../core/NavixScribeV2/api.service.v2";

const RecordedDraftsModal = inject("navixScribeV2Store")(
  observer(({ navixScribeV2Store, onClose, onDraftSelected }) => {
    const [drafts, setDrafts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [editingDraft, setEditingDraft] = useState(null);
    const [editTitle, setEditTitle] = useState("");

    useEffect(() => {
      fetchDrafts();
    }, []);

    const fetchDrafts = async () => {
      try {
        const data = await getRecordings();
        setDrafts(data.recordings);
      } catch (error) {
        console.error("Error fetching drafts:", error);
      } finally {
        setLoading(false);
      }
    };

    const handleDraftSelect = (draft) => {
      onDraftSelected(draft);
      onClose();
    };

    const handleEditTitle = async (draft) => {
      try {
        const result = await updateRecordingTitle(draft.recordingId, editTitle);
        if (result.success) {
          await fetchDrafts(); // Refresh the list
          setEditingDraft(null);
        }
      } catch (error) {
        console.error("Error updating title:", error);
      }
    };

    const handleDelete = async (draft) => {
      if (window.confirm("Are you sure you want to delete this recording?")) {
        try {
          await deleteRecording(draft.recordingId);
          await fetchDrafts(); // Refresh the list
        } catch (error) {
          console.error("Error deleting recording:", error);
        }
      }
    };

    return (
      <ModalOverlay onClick={onClose}>
        <ModalContent onClick={(e) => e.stopPropagation()}>
          <CloseButton onClick={onClose}>
            <XIcon className="icon" />
          </CloseButton>

          <ModalHeader>
            <h2>Recorded Drafts</h2>
          </ModalHeader>

          <DraftsList>
            {loading ? (
              <LoadingText>Loading drafts...</LoadingText>
            ) : drafts.length === 0 ? (
              <EmptyText>No recorded drafts found</EmptyText>
            ) : (
              drafts.map((draft) => (
                <DraftItem key={draft.recordingId}>
                  <DraftInfo onClick={() => handleDraftSelect(draft)}>
                    {editingDraft?.recordingId === draft.recordingId ? (
                      <input
                        type="text"
                        value={editTitle}
                        onChange={(e) => setEditTitle(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") handleEditTitle(draft);
                          if (e.key === "Escape") setEditingDraft(null);
                        }}
                        onBlur={() => handleEditTitle(draft)}
                        autoFocus
                      />
                    ) : (
                      <DraftTitle>{draft.title}</DraftTitle>
                    )}
                    <DraftMeta>
                      {formatDistance(new Date(draft.lastUpdated), new Date(), {
                        addSuffix: true,
                      })}
                    </DraftMeta>
                  </DraftInfo>
                  <DraftStatus totalDuration={draft.totalDuration} />
                  <ActionButtons>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditingDraft(draft);
                        setEditTitle(draft.title);
                      }}
                    >
                      <PencilIcon />
                    </IconButton>
                    <IconButton
                      delete
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDelete(draft);
                      }}
                    >
                      <TrashIcon />
                    </IconButton>
                  </ActionButtons>
                </DraftItem>
              ))
            )}
          </DraftsList>
        </ModalContent>
      </ModalOverlay>
    );
  })
);

// Styled components from CustomTemplateModal...

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1100;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ModalHeader = styled.div`
  width: 100%;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;

  h2 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #111;
  }
`;

const DraftsList = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const DraftItem = styled.div`
  padding: 15px;
  border: 1px solid #eee;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s;
  position: relative;

  &:hover {
    background-color: #f8f8f8;
    border-color: #ddd;
  }
`;

const DraftInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const DraftTitle = styled.div`
  font-weight: 500;
  color: #111;
`;

const DraftMeta = styled.div`
  font-size: 0.875rem;
  color: #666;
`;

const DraftStatus = styled.span`
  padding: 6px 12px;
  border-radius: 16px;
  font-size: 0.875rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 4px;

  /* Format: "1:23" for duration */
  &::before {
    content: "⏱️";
    font-size: 0.875rem;
  }

  background-color: #f3f4f6;
  color: #374151;

  /* Convert seconds to MM:SS format */
  ${(props) => {
    const minutes = Math.floor(props.totalDuration / 60);
    const seconds = Math.floor(props.totalDuration % 60);
    return `
      &::after {
        content: '${minutes}:${seconds.toString().padStart(2, "0")}';
      }
    `;
  }}
`;

const LoadingText = styled.div`
  text-align: center;
  color: #666;
  padding: 20px;
`;

const EmptyText = styled.div`
  text-align: center;
  color: #666;
  padding: 20px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
  border-radius: 50%;

  .icon {
    width: 20px;
    height: 20px;
    color: #666;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const ActionButtons = styled.div`
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  gap: 8px;
  opacity: 0;
  transition: opacity 0.2s;

  ${DraftItem}:hover & {
    opacity: 1;
  }
`;

const IconButton = styled.button`
  padding: 6px;
  border-radius: 50%;
  border: none;
  background: #f3f4f6;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;

  &:hover {
    background: ${(props) => (props.delete ? "#fee2e2" : "#e5e7eb")};
    color: ${(props) => (props.delete ? "#dc2626" : "#374151")};
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

export default RecordedDraftsModal;
