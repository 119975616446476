import { useEffect, useRef, useState } from "react";
import { v4 as uuid } from "uuid";
import { getRecording } from "../core/NavixScribeV2/api.service.v2";

const StreamingRecorder = ({
  onChunk,
  onRecordingComplete,
  initialRecordingId = null,
}) => {
  const [isRecording, setIsRecording] = useState(false);
  const streamRef = useRef(null);
  const recorderRef = useRef(null);
  const recordingIdRef = useRef(initialRecordingId || uuid());
  const chunkCountRef = useRef(0);
  const audioContextRef = useRef(null);
  const sourceNodeRef = useRef(null);
  const processorRef = useRef(null);
  const CHUNK_SIZE = 5; // 5 seconds
  const SAMPLE_RATE = 44100;
  const TARGET_BITRATE = 96000;
  const BYTES_PER_SAMPLE = 2;

  const createAudioProcessor = (audioContext, sourceNode) => {
    const bufferSize = audioContext.sampleRate * CHUNK_SIZE;
    let audioBuffer = new Float32Array(0);

    const scriptProcessor = audioContext.createScriptProcessor(16384, 1, 1);
    scriptProcessor.onaudioprocess = async (audioProcessingEvent) => {
      const input = audioProcessingEvent.inputBuffer.getChannelData(0);

      // Append new audio data to our buffer
      const newBuffer = new Float32Array(audioBuffer.length + input.length);
      newBuffer.set(audioBuffer);
      newBuffer.set(input, audioBuffer.length);
      audioBuffer = newBuffer;

      // If we have collected enough data for a chunk
      if (audioBuffer.length >= bufferSize) {
        await processChunk(audioBuffer.slice(0, bufferSize), false);
        audioBuffer = audioBuffer.slice(bufferSize);
      }
    };

    // Helper function to process chunks
    const processChunk = async (buffer, isFinal) => {
      const wavBlob = await convertToWav(buffer, audioContext.sampleRate);
      const duration = buffer.length / audioContext.sampleRate;

      const metadata = {
        chunkNumber: chunkCountRef.current++,
        timestamp: Date.now(),
        recordingId: recordingIdRef.current,
        duration: duration,
      };

      console.log(
        "Sending chunk:",
        metadata.chunkNumber,
        "Size:",
        wavBlob.size,
        "Duration:",
        duration
      );
      await onChunk?.(wavBlob, isFinal, metadata);
    };

    // Add the processor to the ref so we can access it in stopRecording
    processorRef.current = {
      node: scriptProcessor,
      processChunk,
      audioBuffer,
    };

    return scriptProcessor;
  };

  const convertToWav = async (audioBuffer, sampleRate) => {
    // Calculate target samples based on new bitrate
    // (64000 bits/sec) / (16 bits/sample) = 4000 samples/sec
    const targetSamplesPerSecond = TARGET_BITRATE / (8 * BYTES_PER_SAMPLE);
    const duration = audioBuffer.length / sampleRate;
    const targetLength = Math.floor(targetSamplesPerSecond * duration);
    const newSampleRate = Math.floor(targetSamplesPerSecond);

    // Apply a simple low-pass filter while downsampling
    const downsampledBuffer = new Float32Array(targetLength);
    const stepSize = audioBuffer.length / targetLength;
    const filterSize = 3; // Small filter size to preserve voice frequencies

    for (let i = 0; i < targetLength; i++) {
      const position = Math.floor(i * stepSize);
      let sum = 0;
      let count = 0;

      // Apply triangular filter window for better frequency response
      for (let j = -filterSize; j <= filterSize; j++) {
        const idx = position + j;
        if (idx >= 0 && idx < audioBuffer.length) {
          // Triangular window weight
          const weight = 1 - Math.abs(j) / (filterSize + 1);
          sum += audioBuffer[idx] * weight;
          count += weight;
        }
      }
      downsampledBuffer[i] = sum / count;
    }

    // Normalize the output to prevent clipping
    let maxAmp = 0;
    for (let i = 0; i < downsampledBuffer.length; i++) {
      maxAmp = Math.max(maxAmp, Math.abs(downsampledBuffer[i]));
    }
    if (maxAmp > 0) {
      const gain = Math.min(0.9 / maxAmp, 1.0);
      for (let i = 0; i < downsampledBuffer.length; i++) {
        downsampledBuffer[i] *= gain;
      }
    }

    // WAV file header
    const header = new ArrayBuffer(44);
    const view = new DataView(header);

    // "RIFF" chunk descriptor
    view.setUint8(0, "R".charCodeAt(0));
    view.setUint8(1, "I".charCodeAt(0));
    view.setUint8(2, "F".charCodeAt(0));
    view.setUint8(3, "F".charCodeAt(0));

    // file length
    const dataLength = downsampledBuffer.length * BYTES_PER_SAMPLE;
    view.setUint32(4, 36 + dataLength, true);

    // "WAVE" format
    view.setUint8(8, "W".charCodeAt(0));
    view.setUint8(9, "A".charCodeAt(0));
    view.setUint8(10, "V".charCodeAt(0));
    view.setUint8(11, "E".charCodeAt(0));

    // "fmt " sub-chunk
    view.setUint8(12, "f".charCodeAt(0));
    view.setUint8(13, "m".charCodeAt(0));
    view.setUint8(14, "t".charCodeAt(0));
    view.setUint8(15, " ".charCodeAt(0));

    view.setUint32(16, 16, true); // fmt chunk length
    view.setUint16(20, 1, true); // PCM format
    view.setUint16(22, 1, true); // mono
    view.setUint32(24, newSampleRate, true); // new sample rate
    view.setUint32(28, newSampleRate * BYTES_PER_SAMPLE, true); // byte rate
    view.setUint16(32, 2, true); // block align
    view.setUint16(34, 16, true); // bits per sample

    // "data" sub-chunk
    view.setUint8(36, "d".charCodeAt(0));
    view.setUint8(37, "a".charCodeAt(0));
    view.setUint8(38, "t".charCodeAt(0));
    view.setUint8(39, "a".charCodeAt(0));

    view.setUint32(40, dataLength, true);

    // Convert to 16-bit PCM
    const audioData = new Int16Array(downsampledBuffer.length);
    for (let i = 0; i < downsampledBuffer.length; i++) {
      const s = Math.max(-1, Math.min(1, downsampledBuffer[i]));
      audioData[i] = s < 0 ? s * 0x8000 : s * 0x7fff;
    }

    const blob = new Blob([header, audioData], { type: "audio/wav" });
    return blob;
  };

  const startRecording = async (resumeRecordingId = null) => {
    try {
      if (resumeRecordingId) {
        recordingIdRef.current = resumeRecordingId;
        const response = await getRecording(resumeRecordingId);
        chunkCountRef.current = response.recording.totalChunks;
        console.log(
          "Resuming recording:",
          resumeRecordingId,
          "from chunk:",
          chunkCountRef.current
        );
      } else {
        recordingIdRef.current = initialRecordingId || uuid();
        chunkCountRef.current = 0;
      }

      const stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          channelCount: 1,
          sampleRate: SAMPLE_RATE,
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true,
        },
      });

      streamRef.current = stream;

      // Create audio context and nodes
      audioContextRef.current = new (window.AudioContext ||
        window.webkitAudioContext)({
        sampleRate: SAMPLE_RATE,
      });
      sourceNodeRef.current =
        audioContextRef.current.createMediaStreamSource(stream);

      // Add compression to reduce dynamic range
      const compressor = audioContextRef.current.createDynamicsCompressor();
      compressor.threshold.value = -24;
      compressor.knee.value = 30;
      compressor.ratio.value = 12;
      compressor.attack.value = 0.003;
      compressor.release.value = 0.25;

      processorRef.current = createAudioProcessor(
        audioContextRef.current,
        sourceNodeRef.current
      );

      // Connect the nodes
      sourceNodeRef.current.connect(compressor);
      compressor.connect(processorRef.current);
      processorRef.current.connect(audioContextRef.current.destination);

      setIsRecording(true);
      return recordingIdRef.current;
    } catch (error) {
      console.error("Error starting recording:", error);
      throw error;
    }
  };

  const stopRecording = async () => {
    if (isRecording) {
      try {
        // Process the final chunk if there's any remaining audio data
        if (processorRef.current?.audioBuffer?.length > 0) {
          await processorRef.current.processChunk(
            processorRef.current.audioBuffer,
            true
          );
        }

        // Disconnect and cleanup audio nodes
        if (processorRef.current?.node) {
          processorRef.current.node.disconnect();
          sourceNodeRef.current?.disconnect();
        }

        // Stop all tracks
        streamRef.current?.getTracks().forEach((track) => track.stop());

        // Close audio context
        audioContextRef.current?.close();

        setIsRecording(false);
        console.log("Recording stopped. Total chunks:", chunkCountRef.current);

        onRecordingComplete?.(null, {
          recordingId: recordingIdRef.current,
          totalChunks: chunkCountRef.current,
        });
      } catch (error) {
        console.error("Error stopping recording:", error);
      }
    }
  };

  useEffect(() => {
    return () => {
      if (isRecording) {
        stopRecording();
      }
    };
  }, []);

  return {
    startRecording,
    stopRecording,
    isRecording,
    currentRecordingId: recordingIdRef.current,
  };
};

export default StreamingRecorder;
