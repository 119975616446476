import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { observer, inject } from "mobx-react";
import { useParams } from "react-router-dom";
import CheckoutForm from "./CheckoutForm";

const stripeKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
if (!stripeKey) {
  console.error(
    "Stripe publishable key is missing. Check your environment variables."
  );
}

const stripePromise = loadStripe(stripeKey);

const CheckoutPage = inject("store")(
  observer(({ store }) => {
    const { plan } = useParams();
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
      const fetchProduct = async () => {
        try {
          const response = await store.api.get("/stripe/products");

          if (response.data.success) {
            let selectedProduct;

            if (plan === "STRIPE_PRODUCT_ENTRY") {
              selectedProduct = response.data.products.find(
                (p) => p.name === "Navix AI Starter"
              );
            } else if (plan === "STRIPE_PRODUCT_PRO") {
              selectedProduct = response.data.products.find(
                (p) => p.name === "NavixAI Professional" && p.price === "58.99"
              );
            }

            if (!selectedProduct) {
              throw new Error("Selected plan not found");
            }

            setProduct(selectedProduct);
          }
        } catch (err) {
          console.error("Error:", err);
          setError(err.message);
        } finally {
          setLoading(false);
        }
      };

      if (plan) {
        fetchProduct();
      }
    }, [plan, store.api]);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;
    if (!product) return <div>Product not found</div>;

    const options = {
      mode: "subscription",
      amount: parseInt(product.price * 100),
      currency: "usd",
      appearance: {
        theme: "stripe",
        variables: {
          colorPrimary: "#4336AB",
        },
      },
      loader: "auto",
    };

    return (
      <div className="min-h-screen bg-gray-50 py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* Plan Summary */}
            <div className="bg-white p-6 rounded-lg shadow">
              <div className="flex items-center justify-between">
                <h2 className="text-2xl font-bold text-gray-900">
                  Subscribe to {product.name}
                </h2>
              </div>

              <div className="mt-4">
                <div className="flex items-baseline">
                  <span className="text-4xl font-bold">${product.price}</span>
                  <span className="ml-1 text-gray-500">
                    per {product.interval}
                  </span>
                </div>

                <p className="mt-4 text-gray-600">{product.description}</p>
              </div>
            </div>

            {/* Payment Form */}
            <div className="bg-white p-6 rounded-lg shadow">
              <Elements stripe={stripePromise} options={options}>
                <CheckoutForm product={product} email={store.profile.email} />
              </Elements>
            </div>
          </div>
        </div>
      </div>
    );
  })
);

export default CheckoutPage;
