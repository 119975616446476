import React from "react";
import { CheckCircleIcon } from "@heroicons/react/solid";
import { observer, inject } from "mobx-react";

const PaymentSuccessModal = inject("store")(
  observer(({ store, isOpen, onClose }) => {
    if (!isOpen) return null;

    const planName = store.profile.plan === "pro" ? "Professional" : "Starter";
    const credits = store.profile.credits;

    return (
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div
          className="flex min-h-screen items-center justify-center p-4"
          onClick={onClose}
        >
          <div className="fixed inset-0 bg-black bg-opacity-20 transition-opacity" />

          <div
            className="relative w-full max-w-md"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="bg-white p-8 rounded-xl shadow-xl text-center">
              <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100 mb-4">
                <CheckCircleIcon className="h-8 w-8 text-green-600" />
              </div>

              <h2 className="text-2xl font-bold text-gray-900 mb-4">
                Welcome to NavixAI {planName}!
              </h2>

              <p className="text-gray-600 mb-6">
                Your subscription has been activated successfully. You now have{" "}
                {credits} credits available to use.
              </p>

              <div className="space-y-4">
                <p className="text-sm text-gray-500">
                  Your next billing date will be{" "}
                  {new Date(
                    store.profile.current_period_end
                  ).toLocaleDateString()}
                </p>

                <button
                  onClick={onClose}
                  className="w-full bg-[#4336AB] text-white rounded-lg py-3 font-medium hover:opacity-90 transition-colors"
                >
                  Start Using NavixAI
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  })
);

export default PaymentSuccessModal;
