import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";

const TranscriptionLoader = ({ stage = "uploading" }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((oldProgress) => {
        if (stage === "uploading" && oldProgress >= 50) {
          return 50;
        }
        if (oldProgress >= 100) {
          clearInterval(interval);
          return 100;
        }
        return oldProgress + 100 / 600;
      });
    }, 100);

    return () => clearInterval(interval);
  }, [stage]);

  const getMessage = () => {
    switch (stage) {
      case "uploading":
        return {
          heading: "Uploading",
          subheading:
            "Securely uploading your audio to the cloud, please wait for a moment.",
        };
      case "processing":
        return {
          heading: "Processing",
          subheading:
            "Feel free to do other stuff or start a new encounter while we process your audio!",
        };
      default:
        return {
          heading: "Processing",
          subheading: "Processing your audio...",
        };
    }
  };

  const { heading, subheading } = getMessage();

  return (
    <LoaderContainer>
      <ProgressBar progress={progress} />
      <ContentContainer>
        <SpinnerAndTextContainer>
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
          <TextContainer>
            <Heading>{heading}</Heading>
            <Subheading>{subheading}</Subheading>
          </TextContainer>
        </SpinnerAndTextContainer>
      </ContentContainer>
    </LoaderContainer>
  );
};

const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: #f4f0fa;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ProgressBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: ${(props) => props.progress}%;
  background-color: #e0d7ff;
  transition: width 0.1s linear;
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const SpinnerAndTextContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SpinnerContainer = styled.div`
  margin-right: 20px;
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Spinner = styled.div`
  width: 50px;
  height: 50px;
  border: 6px solid #4c1d95;
  border-top: 6px solid #bebebe;
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;
`;

const TextContainer = styled.div`
  text-align: left;
`;

const Heading = styled.h2`
  color: #000000;
  font-size: 14px;
  margin-bottom: 0.5rem;
`;

const Subheading = styled.p`
  color: #000000;
  font-size: 12px;
`;

export default TranscriptionLoader;
