import { useEffect, useState, useMemo } from "react";
import WaveSurfer from "wavesurfer.js";

const useWavesurfer = (containerRef, initialOptions = {}) => {
  const [wavesurfer, setWavesurfer] = useState(null);

  // Memoize the options object to prevent infinite re-renders
  const options = useMemo(
    () => ({
      waveColor: "violet",
      progressColor: "purple",
      cursorWidth: 1,
      normalize: true,
      minimap: true,
      ...initialOptions,
    }),
    [initialOptions]
  );

  useEffect(() => {
    if (!containerRef.current) return; // Ensure the container is available

    const ws = WaveSurfer.create({
      container: containerRef.current,
      ...options,
    });

    setWavesurfer(ws);

    return () => {
      ws.destroy();
    };
  }, [containerRef, options]); // Properly specify dependencies

  return wavesurfer;
};

export default useWavesurfer;
